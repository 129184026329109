<template>
  <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_961_67944"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="20"
      height="24"
    >
      <path
        d="M18.3824 24H1.61765C0.724246 24 0 23.2325 0 22.2857V1.71429C0 0.767512 0.724246 0 1.61765 0H12.5L20 7.94805V22.2857C20 23.2325 19.2757 24 18.3824 24Z"
        fill="url(#paint0_linear_961_67944)"
      />
    </mask>
    <g mask="url(#mask0_961_67944)">
      <path
        d="M18.3824 24H1.61765C0.724246 24 0 23.2325 0 22.2857V1.71429C0 0.767512 0.724246 0 1.61765 0H12.5L20 7.94805V22.2857C20 23.2325 19.2757 24 18.3824 24Z"
        fill="url(#paint1_linear_961_67944)"
      />
      <g filter="url(#filter0_d_961_67944)">
        <path
          d="M20 7.94805H14.1176C13.2243 7.94805 12.5 7.18054 12.5 6.23377V0L20 7.94805Z"
          fill="url(#paint2_linear_961_67944)"
        />
      </g>
      <path
        d="M4.89941 11.7344L5.99512 14.8281L7.08496 11.7344H8.23926V16H7.35742V14.834L7.44531 12.8213L6.29395 16H5.69043L4.54199 12.8242L4.62988 14.834V16H3.75098V11.7344H4.89941ZM12.5107 13.9639C12.5107 14.3838 12.4365 14.752 12.2881 15.0684C12.1396 15.3848 11.9268 15.6289 11.6494 15.8008C11.374 15.9727 11.0576 16.0586 10.7002 16.0586C10.3467 16.0586 10.0312 15.9736 9.75391 15.8037C9.47656 15.6338 9.26172 15.3916 9.10938 15.0771C8.95703 14.7607 8.87988 14.3975 8.87793 13.9873V13.7764C8.87793 13.3564 8.95312 12.9873 9.10352 12.6689C9.25586 12.3486 9.46973 12.1035 9.74512 11.9336C10.0225 11.7617 10.3389 11.6758 10.6943 11.6758C11.0498 11.6758 11.3652 11.7617 11.6406 11.9336C11.918 12.1035 12.1318 12.3486 12.2822 12.6689C12.4346 12.9873 12.5107 13.3555 12.5107 13.7734V13.9639ZM11.6201 13.7705C11.6201 13.3232 11.54 12.9834 11.3799 12.751C11.2197 12.5186 10.9912 12.4023 10.6943 12.4023C10.3994 12.4023 10.1719 12.5176 10.0117 12.748C9.85156 12.9766 9.77051 13.3125 9.76855 13.7559V13.9639C9.76855 14.3994 9.84863 14.7373 10.0088 14.9775C10.1689 15.2178 10.3994 15.3379 10.7002 15.3379C10.9951 15.3379 11.2217 15.2227 11.3799 14.9922C11.5381 14.7598 11.6182 14.4219 11.6201 13.9785V13.7705ZM14.6611 14.9424L15.6279 11.7344H16.6064L15.1211 16H14.2041L12.7246 11.7344H13.7002L14.6611 14.9424Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_961_67944"
        x="-10.5"
        y="-24"
        width="57.5"
        height="57.948"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="1" />
        <feGaussianBlur stdDeviation="12.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_961_67944" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_961_67944"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_961_67944"
        x1="10"
        y1="0"
        x2="10"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF7979" />
        <stop offset="1" stop-color="#E85555" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_961_67944"
        x1="10"
        y1="0"
        x2="10"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFA216" />
        <stop offset="1" stop-color="#EF8100" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_961_67944"
        x1="16.25"
        y1="0"
        x2="16.25"
        y2="7.94805"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF7A1B" />
        <stop offset="1" stop-color="#E86100" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "AI",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
</style>
